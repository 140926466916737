import React, { useEffect } from "react";
import CardPricingComponent from "../components/CardPricingComponent";
export default function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-28">
      <h2 className="text-5xl font-bold text-center">Pricing</h2>
      <div className="grid place-items-center">
        <p className="text-center pt-2 sm:w-[50%]">
          "Pilih paket penerbitan dan percetakan terbaik yang sesuai anggaran
          dan kebutuhan Anda untuk memulai penerbitan karya yang mengesankan."
        </p>
      </div>
      <div className="grid place-items-center pt-10 mb-20">
        <CardPricingComponent />
      </div>
    </div>
  );
}
