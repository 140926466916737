import React, { useEffect } from "react";
import AboutCard from "./../components/AboutCardComponent";
export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-28 pb-56">
      <h2 className="text-center text-5xl font-bold">Tentang Kami</h2>
      <div className="mt-10 grid place-items-center">
        <AboutCard />
      </div>
    </div>
  );
}
