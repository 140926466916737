import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

export default function CardTerbitanComponent({ searchValue }) {
  const [buku, setBuku] = useState([]);

  useEffect(() => {
    fetch("https://admin.mramanah.web.id/api/buku", { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        setBuku(data.data);
      });
  }, []);

  const NamaBuku = buku.filter((data) =>
    data.nama.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="flex flex-wrap gap-5 justify-center px-10 mt-6">
      {NamaBuku.length > 0 ? (
        NamaBuku.map((data) => (
          <Card
            key={data.id}
            sx={{ maxWidth: 315 }}
            className="flex flex-col space-y-4 p-5 cursor-pointer"
            onClick={() => {
              window.location.href = `/buku/${data.id}`;
            }}
          >
            <img
              src={`https://admin.mramanah.web.id/assets/gallery/${data.foto}`}
              alt="foto"
              className="pb-3 m-auto bg-cover"
              style={{ width: "200px", height: "250px" }}
            />
            <h2 className="text-xl text-center font-bold pt-2">{data.nama}</h2>
            <h2 className="text-xl text-center pt-2">{data.kategori}</h2>
            <h2 className="text-xl text-center pt-2 bottom-0">{data.tahun}</h2>
          </Card>
        ))
      ) : (
        <p>Buku Yang Dicari Tidak Ada</p>
      )}
    </div>
  );
}
