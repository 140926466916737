import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

export default function CardReferensi() {
  const [buku, setBuku] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      fetch("https://admin.mramanah.web.id/api/buku", { method: "GET" })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setBuku(data.data);
        });
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const bukuFiltered = buku.filter((data) => data.kategori_id === "6");

  return (
    <div className="flex flex-wrap gap-5 justify-center px-10 mt-6">
      {bukuFiltered.length > 0 ? (
        bukuFiltered.map((data, index) => {
          return (
            <Card
              key={index}
              sx={{ maxWidth: 315 }}
              className="flex flex-col space-y-4 p-5"
              onClick={() => {
                window.location.href = `/buku/${data.id}`;
              }}
            >
              <img
                src={`https://admin.mramanah.web.id/assets/gallery/${data.foto}`}
                alt="foto"
                className="pb-3 m-auto bg-cover"
                style={{ width: "200px", height: "250px" }}
              />
              <h2 className="text-xl text-center font-bold pt-2">
                {data.nama}
              </h2>
              <h2 className="text-xl text-center pt-2">{data.kategori}</h2>
              <h2 className="text-xl text-center pt-2 bottom-0">
                {data.tahun}
              </h2>
            </Card>
          );
        })
      ) : (
        <p>Belum Ada Referensi</p>
      )}
    </div>
  );
}
