import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function BukuDetail() {
  const { id } = useParams();
  const [DetailBuku, setDetailBuku] = useState({});

  useEffect(() => {
    console.log(id);
    fetch(`https://admin.mramanah.web.id/api/buku/${id}`, { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setDetailBuku(data.data);
      });
  }, [id]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(number);
  };

  return (
    <div className="pt-28 pb-28">
      <div className="grid place-items-center">
        <div className="w-[80%] md:w-[90%] md:flex">
          <div className="md:w-1/4">
            <img
              src={`https://admin.mramanah.web.id/assets/gallery/${DetailBuku.foto}`}
              alt="foto"
              className="pb-3 m-auto bg-cover"
              style={{ width: "200px", height: "300px" }}
            />
          </div>
          <div className="md:w-3/4">
            <h2 className="text-2xl font-bold">{DetailBuku.nama}</h2>
            <h2 className="text-2xl font-bold text-[#FC502F] pb-5">
              {formatNumber(DetailBuku.harga)}
            </h2>
            <table>
              <tbody>
                <tr>
                  <td className="w-[120px]">Nomor Produk</td>
                  <td>: {DetailBuku.nomor_produk}</td>
                </tr>
                <tr>
                  <td className="w-[120px]">ISBN</td>
                  <td>: {DetailBuku.isbn}</td>
                </tr>
                <tr>
                  <td className="w-[120px]">Penulis</td>
                  <td>: {DetailBuku.penulis}</td>
                </tr>
                <tr>
                  <td className="w-[120px]">Berat</td>
                  <td>: {DetailBuku.berat}</td>
                </tr>
                <tr>
                  <td className="w-[120px]">Halaman</td>
                  <td>: {DetailBuku.halaman}</td>
                </tr>
              </tbody>
            </table>
            <Link to={"https://wa.me/+6281393097916"}>
              <button className="mt-5 border border-[#FC502F] px-7 py-2 rounded-[7px] bg-[#FC502F] text-white text-xl">
                Beli
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="grid place-items-center pt-10">
        <div className="w-[80%]">
          <h2 className="text-2xl font-bold text-[#FC502F]">
            <span className="border-b-2 border-[#FC502F]">Sinopsis</span>
          </h2>
          <div className="pt-7">
            <p className="text-justify indent-8">{DetailBuku.sinopsis}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
