import React, { useEffect } from "react";
import illustration from "./assets/img/llustration.svg";
import BenefitComponent from "./components/BenefitComponent";
import { Link } from "react-router-dom";
import CardBukuBarunComponent from "./components/CardBukuBaruComponent";
import Card from "@mui/material/Card";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="h-screen background flex justify-center items-center">
        <div className="text-center text-white">
          <h1 className="md:text-[70px] text-[40px] font-bold">Welcome to</h1>
          <h1 className="md:text-[70px] text-[40px] font-bold">
            CV Malik Rizki Amanah
          </h1>
          <Link to="/Terbitan">
            <button className="mt-16 bg-[#FC502F] px-8 py-1 rounded-full">
              Terbitan
            </button>
          </Link>
        </div>
      </div>
      <div className="flex justify-center pt-20">
        <div className="md:w-[70%] w-[90%] lg:flex">
          <div className="lg:w-1/3 grid place-items-center pb-10">
            <img src={illustration} alt="illustration" />
          </div>
          <div className="lg:w-2/3 lg:pl-20 text-justify lg:text-start">
            <h2 className="text-[32px] font-bold">
              <span className="text-[#FC502F]">
                <span className="border-t-4 pt-2 border-[#FC502F]">
                  CV Malik
                </span>{" "}
                Rizki Amanah:
              </span>{" "}
              Solusi Cetak Berkualitas, Inspirasi Kreatif Untuk Bisnis Anda!
            </h2>
            <p className="pt-5 font-normal">
              CV Malik Rizki Amanah adalah platform penerbitan dan percetakan
              terpercaya, menyediakan layanan cetak berkualitas tinggi untuk
              buku, majalah, brosur, dan produk kreatif lainnya. Kami menawarkan
              solusi desain dan cetak yang inovatif, dipadukan dengan layanan
              pelanggan unggul untuk memenuhi kebutuhan bisnis dan proyek
              kreatif Anda.
            </p>
          </div>
        </div>
      </div>
      {/* <div>
        <h2>Terbaru</h2>
      </div> */}
      <BenefitComponent />
      <div className="md:px-20 pt-10">
        <h2 className="text-3xl font-bold pl-10 md:pl-0">
          <span className="border-b-4 pb-3 border-[#FC502F]">Buku Terbaru</span>
        </h2>
        <div className="pt-10 pb-28">
          <CardBukuBarunComponent />
          <div className="grid place-items-center pt-10">
            <Link to="/Terbitan">
              <button className="border border-[#FC502F] px-7 py-3 rounded-[8px]">
                <h2 className="text-[#FC502F]">Lihat Semua</h2>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
