import React from "react";
import icon1 from "../assets/img/icon1.svg";
import icon2 from "../assets/img/icon2.svg";
import icon3 from "../assets/img/icon3.svg";
import icon4 from "../assets/img/icon4.svg";
import icon5 from "../assets/img/icon5.svg";
import icon6 from "../assets/img/icon6.svg";
import icon7 from "../assets/img/icon7.svg";

export default function BenefitComponent() {
  const Benefit = [
    {
      icon: icon1,
      text: "Free Layout",
    },
    {
      icon: icon2,
      text: "Free Edit Penyesuaian Naskah",
    },
    {
      icon: icon3,
      text: "Desain Cover",
    },
    {
      icon: icon4,
      text: "ISBN (Perpusnas)",
    },
    {
      icon: icon5,
      text: "Promotion Online",
    },
    {
      icon: icon6,
      text: "Loyalti 15% Fisik & 25% E Book",
    },
    {
      icon: icon7,
      text: "Sertifikat",
    },
  ];
  return (
    <div className="bg-[#FFF0ED] mt-10 p-10 md:px-20 flex flex-wrap justify-center gap-5 md:gap-10">
      {Benefit.map((data, index) => (
        <div
          className="items md:flex flex-wrap justify-center items-center md:p-0 p-5"
          key={index}
        >
          <div className="md:w-1/3 flex justify-center">
            <img src={data.icon} alt="#" />
          </div>
          <div className="md:w-2/3 text-center md:text-start">
            <h2 className="text-black text-xl font-bold">{data.text}</h2>
          </div>
        </div>
      ))}
    </div>
  );
}
