import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ImageAbout from "./../assets/img/About.svg";

export default function AboutCardComponent() {
  return (
    <Card className="cardAbout m-5" sx={{ maxWidth: "80%" }}>
      <CardContent className="md:flex items-center gap-10">
        <div className="pb-10 md:pb-0">
          <img src={ImageAbout} alt="image_about" width={2000} />
        </div>
        <div>
          <h2 className="text-3xl font-bold pb-8">CV Malik Rizki Amanah</h2>
          <p className="text-justify">
            CV. Malik Rizki Amanah berdiri pada tanggal 22 Mei 2023 yang
            bergerak dalam bidang penerbitan, percetakan dan perdagangan umum.
            Awal dari pendirian CV. Malik Rizki Amanah ini adalah banyaknya
            kebutuhan dari masyarakat yang membutuhkan pendaftaran buku secara
            online, percetakan yang relatif terjangkau dan mempublikasikan karya
            ilmiah dalam bentuk buku mapun jurnal yang dapat di akses kapanpun
            dan dimanapun. CV. Malik Rizki Amanah berlokasi di Perumahan
            Kalimasada Residance, Blok Bonang 8, Desa Kalikidang, Karangnanas,
            Sokaraja Kabupaten Banyumas.
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
