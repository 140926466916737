import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, Link } from "react-router-dom";

const drawerWidth = 240;
const navItems = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Terbitan",
    link: "/Terbitan",
  },
  {
    name: "Pricing",
    link: "/Pricing",
  },
  {
    name: "Tentang",
    link: "/About",
  },
];

function Navbar(props) {
  const location = useLocation();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeButton, setActiveButton] = React.useState("Home");
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    setActiveButton(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="div" sx={{ my: 2 }}>
        <h2 className="text-2xl font-bold p-5">CV Malik Rizki Amanah</h2>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{
                textAlign: "center",
                ...(location.pathname === item.link && {
                  borderRadius: "5px",
                  backgroundColor: "#FC502F",
                  color: "white",
                  // fontWeight: "bold",
                }),
              }}
              component={Link}
              to={item.link}
              onClick={() => {
                setActiveButton(item.name);
              }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "black" }}
          >
            <MenuIcon />{" "}
            <h2 className="text-sm font-bold pl-3">CV Malik Rizki Amanah</h2>
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              color: "black",
            }}
          >
            <h2 className="text-xl font-bold">CV Malik Rizki Amanah</h2>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.name}
                sx={{
                  color: "black",
                  fontSize: "20px",
                  textTransform: "none",
                  "&:hover": {
                    color: "#FC502F",
                    borderBottom: "3px solid #FC502F",
                    // paddingBottom: "2px",
                  },
                  ...(location.pathname === item.link && {
                    borderBottom: "3px solid #FC502F",
                    paddingBottom: "2px",
                    color: "#FC502F",
                    // fontWeight: "bold",
                  }),
                }}
                component={Link}
                to={item.link}
                onClick={() => {
                  setActiveButton(item.name);
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
