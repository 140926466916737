import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
export default function CardPricingComponent() {
  return (
    <div className="w-full flex flex-wrap justify-center gap-9">
      <Card sx={{ borderRadius: "10px" }} className="cardPricing">
        <div className="bg-[#FC502F] py-3">
          <h2 className="font-bold text-3xl text-white text-center">
            Hot Price
          </h2>
        </div>
        <CardContent className="text-center">
          <p className="text-[#FC502F] ">Paket Hemat</p>
          <h2 className="text-4xl font-bold py-3">Rp 400.000</h2>
          <p className="text-[#FC502F] ">Terbit 5 Eksemplar</p>
          <div className="pt-5">
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>Cetak Hitam Putih (A5)</p>
            </div>
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>1 Eks Penulis</p>
            </div>
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>2 Eks Perpusnas</p>
            </div>
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>1 Eks Perpusda</p>
            </div>
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>1 Eks Arsip</p>
            </div>
          </div>
        </CardContent>
        <div className="grid place-items-center pb-10 pt-4">
          <Link to={"https://wa.me/+6281393097916"}>
            <button className="bg-[#FC502F] text-white text-2xl py-2 px-9 rounded-full">
              Beli Paket
            </button>
          </Link>
        </div>
      </Card>
      <Card sx={{ borderRadius: "10px" }} className="cardPricing">
        <div className="py-3">
          <h2 className="font-bold text-3xl text-white text-center">&ndash;</h2>
        </div>
        <CardContent className="text-center">
          <p className="text-[#FC502F] ">Paket Hemat</p>
          <h2 className="text-4xl font-bold py-3">Rp 750.000</h2>
          <p className="text-[#FC502F] ">Terbit 7 Eksemplar</p>
          <div className="pt-5">
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>Cetak Hitam Putih (A5)</p>
            </div>
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>3 Eks Penulis</p>
            </div>
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>2 Eks Perpusnas</p>
            </div>
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>1 Eks Perpusda</p>
            </div>
            <div className="flex items-center gap-2 pb-1">
              <Icon
                icon="icon-park-solid:check-one"
                className="text-[#29D53A]"
              />
              <p>1 Eks Arsip</p>
            </div>
          </div>
        </CardContent>
        <div className="grid place-items-center pb-10 pt-4">
          <Link to={"https://wa.me/+6281393097916"}>
            <button className="bg-[#FC502F] text-white text-2xl py-2 px-9 rounded-full">
              Beli Paket
            </button>
          </Link>
        </div>
      </Card>
    </div>
  );
}
