import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardTerbitanComponent from "./../components/CardTerbitanComponent";
import CardBukuAjar from "./../components/Buku/CardBukuAjar";
import CardFiksi from "../components/Buku/CardFiksi";
import CardBiografi from "../components/Buku/CardBiografi";
import CardCerpen from "../components/Buku/CardCerpen";
import CardMonograf from "../components/Buku/CardMonograf";
import CardNonFiksi from "../components/Buku/CardNonFiksi";
import CardReferensi from "../components/Buku/CardReferensi";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ backgroundColor: "transparent" }}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Terbitan() {
  const [value, setValue] = React.useState(0);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    setValue(0);
  };

  return (
    <div className="pt-28 pb-28">
      <h2 className="text-5xl pb-5 font-bold text-center">Terbitan</h2>
      <p className="text-center lg:px-72 md:px-44 px-10">
        Temukan keanekaragaman pilihan bacaan berkualitas kami yang meliputi
        buku ajar, monograf, referensi, nonfiksi, biografi, cerpen, dan fiksi.
        Dari pengetahuan mendalam hingga cerita yang memikat, kami menghadirkan
        koleksi penerbitan yang mencerminkan kualitas dan keberagaman.
      </p>
      <div className="md:flex md:flex-wrap justify-center pt-5">
        <div
          component="form"
          sx={{
            "& > :not(style)": { m: 0 },
          }}
          noValidate
          autoComplete="off"
          className="md:flex md:w-[70%]"
        >
          <input
            type="text"
            name="search"
            id="search"
            className="px-5 border border-[#FFF0ED] w-full sm:py-0 py-3 drop-shadow"
            onChange={handleInputChange}
          />
          <button className="py-3 px-10 bg-[#FC502F] text-white w-full sm:w-fit">
            Cari
          </button>
        </div>
      </div>
      <div className="pt-10 flex justify-center">
        <div className="w-full md:grid md:place-items-center">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              overflowX: "auto",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
                "& .MuiTabs-scroller": {
                  justifyContent: "flex-start",
                },
                "& .Mui-selected": {
                  color: "#FC502F !important",
                  borderBottom: "1px solid #FC502F",
                },
              }}
            >
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="Buku Ajar" {...a11yProps(1)} />
              <Tab label="Monograf" {...a11yProps(2)} />
              <Tab label="Referensi" {...a11yProps(3)} />
              <Tab label="Nonfiksi" {...a11yProps(4)} />
              <Tab label="Biografi" {...a11yProps(5)} />
              <Tab label="Cerpen" {...a11yProps(6)} />
              <Tab label="Fiksi" {...a11yProps(7)} />
            </Tabs>
          </div>
          <CustomTabPanel value={value} index={0}>
            <CardTerbitanComponent searchValue={searchValue} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CardBukuAjar />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <CardMonograf />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <CardReferensi />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <CardNonFiksi />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <CardBiografi />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            <CardCerpen />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={7}>
            <CardFiksi />
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
}
