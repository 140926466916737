import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

export default function CardBukuBarunComponent() {
  const [buku, setBuku] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://admin.mramanah.web.id/api/buku", {
          method: "GET",
        });
        const data = await response.json();
        console.log(data);
        setBuku(data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const sortedBuku = buku.sort((a, b) => b.id - a.id);
  const limitedBuku = sortedBuku.slice(0, 3);

  return (
    <div className="flex flex-wrap gap-5 justify-center px-10 mt-6">
      {limitedBuku.map((data, index) => (
        <Card
          key={index}
          sx={{ maxWidth: 315, width: "100%" }}
          className="flex flex-col space-y-4 p-5 cursor-pointer"
          onClick={() => {
            window.location.href = `/buku/${data.id}`;
          }}
        >
          <img
            src={`https://admin.mramanah.web.id/assets/gallery/${data.foto}`}
            alt="foto"
            className="pb-3 m-auto bg-cover"
            style={{ width: "200px", height: "250px" }}
            loading="lazy"
          />
          <h2 className="text-xl text-center font-bold pt-2">{data.nama}</h2>
          <h2 className="text-xl text-center pt-2">{data.kategori}</h2>
          <h2 className="text-xl text-center pt-2 bottom-0">{data.tahun}</h2>
        </Card>
      ))}
    </div>
  );
}
