import React from "react";
import { Icon } from "@iconify/react";
import ScrollToTopButton from "../../components/ToTopButton";
import { Link } from "react-router-dom";

export default function Footer() {
  const sosmed = [
    {
      link: "#",
      icon: "mdi:instagram",
    },
    {
      link: "https://wa.me/+6281393097916",
      icon: "mdi:whatsapp",
    },
    {
      link: "#",
      icon: "mdi:facebook",
    },
  ];

  const perusahaan = [
    {
      name: "Tentang kami",
      link: "#",
    },
    {
      name: "Kebijakan dan Privasi",
      link: "#",
    },
    {
      name: "Kontak Kami",
      link: "#",
    },
    {
      name: "FAQ",
      link: "#",
    },
  ];

  return (
    <div className="pt-10 grid place-items-center gap-2 bg-[#3D3E43] pb-10">
      <ScrollToTopButton />
      <div className="md:w-2/3 w-[90%] md:flex gap-14">
        <div className="md:w-1/2 text-white">
          <h2 className="text-3xl font-bold">CV Malik Rizki Amanah</h2>
          <p>
            Solusi cetak dan penerbitan berkualitas tinggi dari CV Malik Rizki
            Amanah. Layanan inovatif untuk buku, majalah, dan produk kreatif.
          </p>
          <div className="flex gap-2 pt-5 text-[#FC502F]">
            {sosmed.map((data, index) => (
              <Link to={data.link} key={index}>
                <button className="border rounded-full border-[#FC502F] p-1">
                  <Icon icon={data.icon} width={25} />
                </button>
              </Link>
            ))}
          </div>
        </div>
        <div className="md:w-1/2 md:pl-20 text-white md:pt-0 pt-10">
          <h2 className="text-3xl">Perusahaan</h2>
          <div>
            {perusahaan.map((data, index) => (
              <div key={index}>
                <button>{data.name}</button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="text-white w-[90%] md:w-2/3">
        <h2 className="text-start pt-10">
          &copy; Copyright CV Malik Rizki Amanah - 2023 All Right Reserved
        </h2>
      </div>
    </div>
  );
}
