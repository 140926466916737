import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex">
      <Link to={"https://wa.me/+6281393097916"}>
        <button className="fixed bottom-10 right-[75px] text-[#25D366] bg-[#25D366] rounded-[8px] p-[6px]">
          <Icon icon="logos:whatsapp-icon" width={20} />
        </button>
      </Link>
      <button
        className={`${
          isVisible ? "block" : "hidden"
        } fixed bottom-10 right-10 bg-[#FC502F] text-white rounded-md p-1`}
        onClick={scrollToTop}
      >
        <Icon icon="mdi:arrow-top-bold-outline" width={24} />
      </button>
    </div>
  );
}
