import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
// import "./app.css";
import App from "./App";
import Navbar from "./pages/templates/Navbar";
import Footer from "./pages/templates/Footer";
import About from "./pages/About";
import Pricing from "./pages/Pricing";
import Terbitan from "./pages/Terbitan";
import reportWebVitals from "./reportWebVitals";
import BukuDetail from "./pages/BukuDetail";
const RedirectComponent = () => {
  useEffect(() => {
    window.location.href = "https://mramanah.web.id/sign";
  }, []);

  return null; // The component itself doesn't render anything, so we return null.
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Terbitan" element={<Terbitan />} />
        <Route path="/About" element={<About />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/buku/:id" element={<BukuDetail />} />
        <Route path="/sign" element={<RedirectComponent />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
